.App {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-size: 80%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  text-align: center;
}

.container {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.button-group {
  margin-bottom: 10px;
}

button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.scroll-area {
  flex-grow: 1;
  overflow-y: auto;
}

.location-card {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
}

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.report-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.report-item.maintenance {
  font-style: italic;
}

.report-item .date {
  margin-right: 15px;
}

.alert {
  background-color: #ffcccc;
  border: 1px solid #ff0000;
  color: #ff0000;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.report-key {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
  text-align: center;
  font-size: 0.9em;
}

.normal-report {
  font-style: normal;
}

.maintenance-report {
  font-style: italic;
}

.disclaimer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  font-style: italic;
}
